<template>
    <div>
        <v-title v-if="Object.keys(response).length" :title="`${response.data.groupName} - Customer Groups`"></v-title>
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Edit customers group</h1>
                </div>
            </div>
        </title-box>
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-7">
                    <customer-group-form v-if="Object.keys(response).length" :form="response.data" class="mt-n4" :action="action" message="Customer Group was updated"></customer-group-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ResourcesView from '@/views/ResourcesView'
import CONFIG from '@root/config'
import CustomerGroupForm from '@/components/elements/customers/CustomerGroupForm'

export default {
    name: 'EditCustomersGroup',
    mixins: [ResourcesView],
    components: { CustomerGroupForm },
    data () {
        return {
            url: CONFIG.API.ROUTES.CUSTOMERS.GROUPS.GET.replace('{groupId}', this.$route.params.customersGroupId),
            action: CONFIG.API.ROUTES.CUSTOMERS.GROUPS.UPDATE,
        }
    },
    computed: {
        breadCrumbId () {
            if (!Object.keys(this.response).length) {
                return '...'
            }
            return this.response.data.groupName
        },
    },
}
</script>
